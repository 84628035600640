.wrapper {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.login-wrapper,
.fp-wrapper {
  background-color: #5DC4B9;
  min-height: 100vh;
}

.fullpage {
  display: flex !important;
  flex-direction: column;
  padding-bottom: 100px;
  position: relative;
}

.login-wrapper .fullpage .MuiGrid-container,
.hmm-logo {
  z-index: 1;
}

.hmm-logo {
  margin: 2.5rem auto 2rem !important;
  text-align: center;
}

.hmm-logo img {
  height: 7rem;
}

.login-content {
  margin: auto !important;
}

.auth-user-head {
  height: 45vh;
  background-image: url('../../assets/img/login/login-bkg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.auth-user-form {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 4vh 0 0;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

.loading-profile {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

span.loading-profile-welcome {
  font-size: 30px;
  color: #022E5E;
  margin: 15px 20px 0px 20px;
}

span.loading-profile-welcome::after {
  content: ','
}

span.loading-profile-username {
  color: #022E5E;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  font-weight: bolder;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.circular-progress {
  color: #5DC4B9 !important;
}

.auth-user-card {
  border-radius: 0.75rem !important;
  max-width: 420px !important;
  padding: 30px 15px;
  margin: 0 !important;
}

.auth-user-card .MuiFormControl-root {
  width: 100%;
  margin-top: 15px;
  background-color: #B7BDC5;
  border-radius: 10px;
}

.auth-user-card .MuiFilledInput-adornedStart,
.auth-user-page .MuiIconButton-label {
  color: #fff;
}

.login-remember-me .MuiIconButton-label {
  color: #022E5E !important;
}

.auth-user-card .MuiTypography-body1 {
  color: #022E5E !important;
  font-size: .95rem;
}

.MuiFilledInput-input {
  padding: 15px !important;
}

.MuiInputAdornment-positionStart {
  padding-bottom: 15px !important;
}

.MuiInputAdornment-positionStart .MuiSvgIcon-root {
  font-size: 2rem !important;
}

#login-form .MuiFilledInput-underline:after {
  border-bottom: 1px solid #999;
}

.login-card .MuiAutocomplete-root>.MuiTextField-root>.MuiInput-underline:after,
.login-card .MuiAutocomplete-root>.MuiTextField-root>.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #999 !important;
}

.login-card .select-input-container>.MuiAutocomplete-root>.MuiTextField-root>.MuiInput-underline::before,
.login-card .MuiFilledInput-underline:before {
  border-bottom: 1px solid transparent !important;
}

.login-sign-in {
  flex-direction: column;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
}

.auth-user-page .submit-button {
  background-color: #5DC4B9 !important;
  font-weight: bolder !important;
  margin-top: 16px;
  width: 100%;
}

.auth-user-page .link-content {
  margin-top: 8px;
  text-align: center;
}

.right {
  text-align: right;
}

.auth-user-page .link-content .link-button {
  color: #022E5E;
  font-size: .875rem;
  margin: 0;
  text-transform: none;
}

.auth-user-page .link-content .link-button:hover {
  text-decoration: underline;
}

/*button submit*/
.RegularButton {
  color: #fff;
  border: none;
  margin: 0.3125rem 1px !important;
  cursor: pointer;
  padding: 12px 30px;
  position: relative;
  min-width: auto;
  font-size: 12px;
  min-height: auto;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14),
    0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  font-weight: 400 !important;
  will-change: box-shadow, transform;
  line-height: 1.42857143;
  white-space: nowrap;
  touch-action: manipulation;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 0;
  vertical-align: middle;
  background-color: #999;
}

.RegularButton-block {
  width: 100% !important;
}

button.RegularButton-lg {
  padding: 1.125rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.333333;
  border-radius: 0.2rem;
}

.RegularButton-rose {
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14),
    0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
  background-color: #e91e63;
}

.RegularButton-simple,
.RegularButton-simple:focus,
.RegularButton-simple:hover {
  color: #fff;
  background: transparent;
  box-shadow: none;
}

.RegularButton-simple.RegularButton-rose,
.RegularButton-simple.RegularButton-rose:focus,
.RegularButton-simple.RegularButton-rose:hover,
.RegularButton-simple.RegularButton-rose:visited {
  color: #e91e63;
}

.MuiFilledInput-underline.Mui-error:after {
  border-color: #EA5757 !important;
}

/* Select  AdornedStart */
.auth-user-page .MuiFilledInput-underline:after {
  border-width: 1px !important;
}

.company .select-input-container {
  padding: 0 !important;
}

.company .select-input-container .MuiTextField-root {
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
}

.company .select-input-container .MuiAutocomplete-inputRoot {
  padding-left: 12px;
}

.company .select-input-container .MuiInputBase-inputAdornedStart {
  padding: 1px 12px 12px !important;
}

.company .select-input-container .MuiInputAdornment-positionStart svg {
  color: white;
}

.auth-user-page .MuiFilledInput-root {
  background-color: transparent;
  border-radius: 10px;
  overflow: hidden;
}

.auth-user-page .MuiInputBase-root .MuiInputBase-input,
.auth-user-page .select-input .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input,
.auth-user-page .MuiGrid-root .MuiInputBase-input.Mui-disabled {
  color: white !important;
}

.auth-user-page .select-input .MuiAutocomplete-endAdornment {
  right: 15px;
}

#select-language {
  border-color: #B7BDC5;
  border-radius: 4px;
  color: #012e5e;
  font-size: .8rem;
  padding: 3px 4px;
}

.pwd-by-link {
  position: absolute;
  bottom: 20px;
  left: calc(50% - 53px);
}

.pwd-by-link img {
  height: 4rem;
}

#button-chatbot {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 60px;
  z-index: 999999999;
  height: 60px;
  background-color: #2b8279;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  border: 0px solid white;
}

.chat-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

